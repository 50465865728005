import React from "react"
import styled from "styled-components"

import { ethers } from "ethers"
import Web3Modal from "web3modal"
import WalletConnectProvider from "@walletconnect/web3-provider"

const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`

const Button = styled.div`
    border-radius: 5px;
    padding: 0.5em;
    margin: 2em auto;
    background: #FFFFCF;
    color: #212121;
    width: fit-content;

    &:hover {
        cursor: pointer;
    }
`

const isBrowser: boolean = typeof window !== "undefined"
let providerOptions: any, web3Modal: any;

if(isBrowser) {
    providerOptions = {
        // Example with injected providers
        injected: {
            display: {
                description: "Connect with the provider in your Browser"
            },
        package: null
        },
        // Example with WalletConnect provider
        walletconnect: {
            display: {
                name: "Mobile",
                description: "Scan qrcode with your mobile wallet"
            },
            package: WalletConnectProvider,
            options: {
                infuraId: "154ede9d229f4a1bb54f6fa80afbe920"
            }
        }
    };

    web3Modal = new Web3Modal({
        network: "mainnet",
        cacheProvider: false,
        providerOptions
    });
}

const ConnectWallet = (props: any) => {

    const handleClick = async () => {

        if(!isBrowser) {
            return;
        }

        let instance;
        try {
            instance = await web3Modal.connect();
        } catch(e) {
            return null
        }

        const provider = new ethers.providers.Web3Provider(instance);
        const signer = provider.getSigner();
        const addresses = await provider.listAccounts();

        props.callback({
            nextStep: 1,
            web3: {
                provider,
                signer,
                activeAddress: addresses[0]
            }
        })
    }

    return (<>
        Connect your Web3 wallet
        <SubTitle>
            After you have connected your Web3 wallet, we will verify you are a #timekeeper and if you have multiple ENS names, you can choose which one to use.

            <Button onClick={() => handleClick()}>
                Connect Wallet
            </Button>
        </SubTitle>
    </>)
}

export default ConnectWallet